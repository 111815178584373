import { Injectable } from '@angular/core';
import { FormUser, SignupUser } from 'src/app/interfaces/user.interface';
import { Country } from '../../interfaces/country.interface';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedLanguage, ParseLanguage, VoiceLanguage } from '../../interfaces/language.interface';
import { globals } from 'src/globals';
import { NgxAnalytics } from 'ngx-analytics';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private http: HttpClient,
    private ga: NgxAnalytics
  ) {
  }

  static convertFormUserToSignupUser(formUser: FormUser, langCode: string): SignupUser {

    const langCodes: string[] = [];

    if (langCode === globals.standardActivatedLanguage) {
      langCodes.push(langCode);
    } else {
      langCodes.push(globals.standardActivatedLanguage);
      langCodes.push(langCode);
    }

    return {
      username: formUser.email,
      email: formUser.email,
      password: formUser.passwords.password,
      translationPassword: formUser.translationPassword,
      textAzureKey: formUser.textAzureKey,
      voiceAzureKey: formUser.voiceAzureKey,
      contactName: formUser.contactName,
      churchName: formUser.churchName,
      street: formUser.street,
      postal: formUser.postal,
      city: formUser.city,
      country: formUser.country,
      languages: langCodes,
      settings: {
        bible: "de4e12af7f28f599-02",
        archiveLanguage: "de",
        translationLanguage: "en",
        voiceLanguage: "en-US"
      }
    };
  }

  static transformDateStringFromNumber(number): string {
    let output;

    if (number < 1 || number > 31) {
      throw new Error('Number has to be between 1 and 31. (Day of a Month)');
    }

    if (number < 10) {
      output = '0' + number;
    } else {
      output = number.toString();
    }

    return output;
  }

  static getDateString(date: Date): string {

    const dd = SharedService.transformDateStringFromNumber(date.getDate());
    const mm = SharedService.transformDateStringFromNumber(date.getMonth() + 1);
    const yyyy = date.getUTCFullYear();

    return dd + '.' + mm + '.' + yyyy;
  }

  static createNewSundayDate(): Date {
    const date = new Date();
    const dayOfWeek = date.getDay();

    if (dayOfWeek === 0) {
      return date;
    } else {
      const daysTillSunday = 7 - dayOfWeek;
      date.setDate(date.getDate() + daysTillSunday);

      return date;
    }
  }

  static createLanguagesArray(objects: Parse.Object[]): ParseLanguage[] {
    const languagesArray: ParseLanguage[] = [];

    for (const object of objects) {
      const language: ParseLanguage = object.attributes;
      languagesArray.push(language);
    }
    return languagesArray.sort(SharedService.sortLanguagesArray);
  }

  static createVoiceLanguagesArray(objects: Parse.Object[]): VoiceLanguage[] {
    const languagesArray: VoiceLanguage[] = [];

    for (const object of objects) {
      const language: VoiceLanguage = object.attributes;
      languagesArray.push(language);
    }
    return languagesArray.sort(SharedService.sortLanguagesArray);
  }

  static sortLanguagesArray(a: any, b: any): number {
    if (a.name > b.name) {
      return 1;
    }
    if (b.name > a.name) {
      return -1;
    }

    return 0;
  }

  static isActivatedLanguageAlreadyInArray(langCode: string, activatedLanguages: ActivatedLanguage[]): boolean {

    const index = activatedLanguages.find(language => language.langCode === langCode);

    return !!index;
  }

  createActivatedLanguages(langCodes: string[], languages: ParseLanguage[]): ActivatedLanguage[] {
    const activatedLanguages: ActivatedLanguage[] = [];

    for (const lang of langCodes) {
      const langCode = lang;
      const name = languages.find(obj => obj.langCode === langCode).name;

      activatedLanguages.push({ langCode: langCode, name: name });
    }

    return activatedLanguages.sort(SharedService.sortLanguagesArray);

  }

  loadAllCountries(): Observable<Country[]> {
    return this.http.get('https://restcountries.eu/rest/v2/all') as Observable<Country[]>;
  }

  gaTrackEvent(action: string, category?: string, label?: string): void {
    this.ga.eventTrack.next({
      action: action,
      properties: {
        category: category,
        label: label,
      },
    });
  }

}
