import { FormGroup } from '@angular/forms';

export class PasswordValidator {
  static validate(registrationFormGroup: FormGroup) {
    const password = registrationFormGroup.controls['password'].value;
    const passwordConfirm = registrationFormGroup.controls['passwordConfirm'].value;

    if (passwordConfirm.length <= 0) {
      return null;
    }

    if (passwordConfirm !== password) {
      return {doesNotMatchPassword: true};
    }

    return null;

  }
}
