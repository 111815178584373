import { Component, OnDestroy, OnInit } from '@angular/core';
import { TextTranslation, TranslationPreparation } from '../../../interfaces/styles.interface';
import { TranslationService } from '../../../services/parse/translation/translation.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { LanguagesService } from '../../../services/parse/languages/languages.service';
import { PreparationService } from 'src/app/services/parse/preparation/preparation.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { Preparation } from 'src/app/interfaces/preparation.interface';

import * as Parse from 'parse';
import { VoiceTranslationService } from 'src/app/services/voice/voice-translation.service';
import { BiblesService } from '../../../services/bibles/bibles.service';
import { SettingsService } from '../../../services/parse/settings/settings.service';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss']
})
export class TranslationComponent implements OnInit, OnDestroy {

  text_translation_style: TextTranslation;
  translation_preparation_style: TranslationPreparation;

  date: string;
  preparation: Preparation;
  preparations: Preparation[];

  textarea = '';
  enterKey = false;
  shiftKey = false;

  voice = false;
  recording = false;

  constructor(
    public translation: TranslationService,
    private toastr: ToastrService,
    private translate: TranslateService,
    public prep: PreparationService,
    private voiceService: VoiceTranslationService,
    private language: LanguagesService,
    private shared: SharedService,
    private biblesService: BiblesService,
    private settings: SettingsService
    ) {}

  ngOnInit(): void  {
    this.translation.getTranslations();

    this.date = SharedService.getDateString(new Date());
    this.preparationsAvailable();
    this.voiceAvailable();

    this.language.loadLanguages()
      .then(() => this.language.loadActivatedLanguages())
      .then(() => this.language.loadVoiceLanguages())
      .catch(err => console.log(err));
  }

  ngOnDestroy(): void {
    this.translation.unsubscribeTranslations();
  }

  voiceAvailable() {
   const voiceKey = Parse.User.current().attributes.voiceAzureKey;
   this.voice = voiceKey !== '';
  }

  async preparationsAvailable() {
    await this.prep.getPreparationsGroupedByDate();
    this.preparations = this.prep.preparations[this.date];
    if (!this.preparations) {
      this.removePreparationsList();
    } else {
      this.viewPreparationsList();
    }
  }

  viewPreparationsList(): void {
    this.text_translation_style = undefined;
    this.translation_preparation_style = undefined;
  }

  removePreparationsList(): void {
    this.text_translation_style = {
      gridTemplateAreas: '"translation_list translation_list ." "translation_textarea translation_bibles send_button"'
    };
    this.translation_preparation_style = { display: 'none' };
  }

  sendPreparation(preparation: Preparation) {
    const { chapterId, fromVerseId, toVerseId } = preparation;
    preparation.isVerse ?
      this.biblesService.sendVerses(chapterId, fromVerseId, toVerseId) :
      this.translation.translate(preparation.text);
    this.removePreparation(preparation);
    this.shared.gaTrackEvent('sendPreparation', 'Translation');
  }

  async removePreparation(preparation: Preparation) {
    await this.prep.deletePreparation(preparation);
    this.preparationsAvailable();
  }

  send(): void {

    if (this.textareaIsNotEmpty()) {

      if (this.recording) {
        this.record();
        this.record();
      }

      this.translation.translate(this.textarea);
      this.textarea = '';
      this.language.activatedLanguages.forEach(langCode => {
        this.shared.gaTrackEvent('send', 'Translation', langCode.langCode);
      });
    } else {
      this.showEmptyTextareaMessage();
    }
    document.getElementById('textarea').focus();
  }

  record(): void {
    if (this.recording) {
      this.voiceService.stopRecording();
      this.recording = false;
    } else {
      this.voiceService.startRecording();
      this.voiceService.startListening()
        .subscribe((text: string) => this.textarea = text);
      this.recording = true;
      this.shared.gaTrackEvent('record', 'Translation', this.settings.selectedVoiceLanguage);
    }

  }

  onKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
     this.enterKey = true;
    }
    if (event.key === 'Shift') {
      this.shiftKey = true;
    }

    if (this.enterKey && this.shiftKey) {
      this.send();
      event.preventDefault();
    }
  }

  onKeyup(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.enterKey = false;
    }
    if (event.key === 'Shift') {
      this.shiftKey = false;
    }
  }

  textareaIsNotEmpty(): boolean {
    return this.textarea !== '';
  }

  showEmptyTextareaMessage(): void {
    this.translate.get('APP.TEXT_TRANSLATION.EMPTY_TEXTAREA_MESSAGE').subscribe(message => {
      this.toastr.info(message);
    });
  }

}
