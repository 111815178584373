import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslationService } from '../../../services/parse/translation/translation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit, OnDestroy {

  constructor(public translation: TranslationService, private router: Router) { }

  ngOnInit(): void {
    this.translation.getArchiveDates();
  }

  ngOnDestroy(): void {
    this.translation.archiveDates = [];
  }

  onClick(date: string) {
    this.router.navigate(['app/archive-details', date]);
  }
}
