import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/parse/authentication/authentication.service';
import { LanguagesService } from 'src/app/services/parse/languages/languages.service';

@Component({
  selector: 'app-translation-app',
  templateUrl: './translation-app.component.html',
  styleUrls: ['./translation-app.component.scss']
})
export class TranslationAppComponent implements OnInit {

  opened = false;

  constructor(private authService: AuthenticationService, private language: LanguagesService) { }

  ngOnInit() {
    this.language.subscribeLanguageRequest();
  }

  toggleSidebar() {
    this.opened = !this.opened;
  }

  logout() {
    this.authService.userLogout();
  }


}
