import { Injectable } from '@angular/core';
import { Preparation, PreparationsGrouped } from 'src/app/interfaces/preparation.interface';
import { SharedService } from '../../shared/shared.service';

import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})
export class PreparationService {

  preparations: PreparationsGrouped;
  preparationsActive: Preparation[] = [];
  preparationsArchive: Preparation[] = [];

  constructor() { }

  async savePreparation(text: string, date: Date) {
    const object = new Parse.Object('Preparations');
    const userId: string = Parse.User.current().id;
    const dateString: string = SharedService.getDateString(date);

    const preparation: Preparation = {
      text: text,
      date: dateString,
      churchId: userId,
      isVerse: false
    };

    const savePreparation = await object.save(preparation);
    this.addPreparation(savePreparation, dateString);
  }

  addPreparation(preparation: Parse.Object, dateString: string) {
    this.preparations[dateString] ?
      this.preparations[dateString].push(this.createPreparation(preparation)) :
      this.preparations[dateString] = [ this.createPreparation(preparation) ];
    this.setPreparations(dateString);
  }

  async getPreparationsGroupedByDate() {
    this.preparations = await Parse.Cloud.run('getPreparationsGroupedByDate');
    this.sortPreparations();
  }

  async editPreparation(preparation: Preparation, index: number) {
    if (preparation.isVerse) {
      console.log('Bible verse is not allowed to edit');
      // TODO Error Message LIKE Archive
      return;
    }

    const Preparations = Parse.Object.extend('Preparations');
    const query = new Parse.Query(Preparations);
    const object = await query.get(preparation.id);

    object.set('text', preparation.text);
    await object.save();
    this.preparationsArchive[index] = preparation;
  }

  async deleteAllPreparations(preparations: Preparation[]) {
    const promises: Promise<any>[] = [];
    preparations.forEach(preparation => promises.push(this.deletePreparation(preparation)));

    return Promise.all(promises);
  }

  async deletePreparation(preparation: Preparation) {

    const Preparations = Parse.Object.extend('Preparations');
    const query = new Parse.Query(Preparations);
    const object = await query.get(preparation.id);

    await object.destroy();
    const id = this.preparationsArchive.findIndex(pred => pred.id === preparation.id);
    this.preparationsArchive.splice(id, 1);
    if (this.preparationsArchive.length === 0) {
      delete this.preparations[preparation.date];
    }
  }

  setPreparations(dateString: string) {
    this.preparationsActive = this.preparations[dateString] || [];
    this.sortPreparations();
  }

  openDetails(dateString: string) {
    this.preparationsArchive = this.preparations[dateString];
  }

  createPreparation(parsePreparation: Parse.Object) {
    return {
      id: parsePreparation.id,
      ...parsePreparation.attributes
    };
  }

  sortPreparations() {
    const ordered: PreparationsGrouped = {};

    Object.keys(this.preparations).sort().forEach(key => ordered[key] = this.preparations[key]);
    this.preparations = ordered;
  }
}

