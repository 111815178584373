import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Parse from 'parse';
import { environment } from 'src/environments/environment';
import { globals } from 'src/globals';

import { NgxAnalyticsGoogleAnalytics } from 'ngx-analytics/ga';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(translate: TranslateService, ga: NgxAnalyticsGoogleAnalytics) {
    translate.setDefaultLang(globals.standardActivatedTranslationLanguage);

    (Parse as any).serverURL = environment.parseServer.serverUrl;
    Parse.initialize(environment.parseServer.applicationKey, environment.parseServer.javascriptKey);
  }
}
