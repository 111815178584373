import { Injectable } from '@angular/core';
import { ActivatedLanguage, ParseLanguage, VoiceLanguage } from '../../../interfaces/language.interface';
import { SharedService } from '../../shared/shared.service';
import { ToastrService } from 'ngx-toastr';

import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {

  constructor (
    private toastr: ToastrService,
    private shared: SharedService,
  ) {}

  languages: ParseLanguage[] = [];
  voiceLanguages: VoiceLanguage[] = [];
  activatedLanguages: ActivatedLanguage[] = [];
  languagesToTranslate: string[] = [];

  loadLanguages(): Promise<ParseLanguage[]> {

    return new Promise((resolve, reject) => {

      const Languages = Parse.Object.extend('Languages');
      const query = new Parse.Query(Languages);

      query.find()
        .then((objects: Parse.Object[]) => {
          const languagesArray = SharedService.createLanguagesArray(objects);
          this.languages = languagesArray;
          resolve(languagesArray);
        })
        .catch((err: Parse.Error) => {
          this.toastr.error(err.message, 'LoadLanguages Error');
          reject(err);
        });

    });
  }

  loadVoiceLanguages(): Promise<VoiceLanguage[]> {

    return new Promise((resolve, reject) => {

      const Languages = Parse.Object.extend('VoiceLanguages');
      const query = new Parse.Query(Languages);

      query.find()
        .then((objects: Parse.Object[]) => {
          const languagesArray = SharedService.createVoiceLanguagesArray(objects);
          this.voiceLanguages = languagesArray;
          resolve(languagesArray);
        })
        .catch((err: Parse.Error) => {
          this.toastr.error(err.message, 'LoadLanguages Error');
          reject(err);
        });

    });
  }

  loadActivatedLanguages(): Promise<ActivatedLanguage[]> {

    return new Promise((resolve, reject) => {

      const User = Parse.Object.extend('User');
      const query = new Parse.Query(User);

      query.get(Parse.User.current().id)

        .then((user: Parse.Object) => {
          const activatedLanguagesArray = this.shared.createActivatedLanguages(user.attributes.languages, this.languages);
          this.activatedLanguages = activatedLanguagesArray;
          resolve(activatedLanguagesArray);
        })

        .catch((err: Parse.Error) => {
          this.toastr.error(err.message, 'LoadActivatedLanguages Error');
          reject(err);
        });

    });
  }

  selectActivatedLanguage(language: ActivatedLanguage) {
    const today = SharedService.getDateString(new Date());
    const lang = this.getLanguage(language.langCode);
    Parse.Cloud.run('activateLanguage', { langCode: language.langCode, date: today })

      .then(() => {
        this.languagesToTranslate.push(language.langCode);
        this.toastr.success(lang.name + ' is activated');
        this.loadActivatedLanguages();
      })

      .catch((err: Parse.Error) => {
        this.toastr.error(err.message, 'ActivateLanguage Error');
        this.resetActivatedLanguage(language);
      });
  }

  deSelectActivatedLanguage(language: ActivatedLanguage) {
    Parse.Cloud.run('deActivateLanguage', { langCode: language.langCode })

      .then(() => {
        const index = this.languagesToTranslate.indexOf(language.langCode);
        if (index !== -1) {
          this.languagesToTranslate.splice(index, 1);
        }
        this.toastr.success(language.name + ' is deactivated');
      })

      .catch((err: Parse.Error) => {
        this.toastr.error(err.message, 'DeActivateLanguage Error');
        this.resetDeActivatedLanguage(language);
      });
  }

  resetActivatedLanguage(language: ActivatedLanguage) {
    const index = this.activatedLanguages.indexOf(language);
    this.activatedLanguages.splice(index, 1);
  }

  resetDeActivatedLanguage(language: ActivatedLanguage) {
    this.activatedLanguages.push(language);
    this.activatedLanguages.sort(SharedService.sortLanguagesArray);
  }

  subscribeLanguageRequest() {

    const query = new Parse.Query('LanguageRequest');
    query.equalTo('churchId', Parse.User.current().id);

    query.find()

    .then((res: Parse.Object[]) => {
      res.forEach(o => this.showRequestToastr(o.attributes.langCode));

      query.subscribe().on('create', (request) => {
        this.showRequestToastr(request.attributes.langCode);
      });


    })

    .catch((err: Parse.Error) => console.log(err));

  }

  async showRequestToastr(langCode: string) {

    const language = this.getLanguage(langCode);

    const toast = this.toastr.info(language.name + ' is requested!', 'Click here to enable the language', {
      enableHtml: true,
      closeButton: true,
      disableTimeOut: true,
    });

    toast.onTap.subscribe(() => {
      this.selectActivatedLanguage(language);
    });

    toast.onHidden.subscribe(() => {
     this.deleteRequest(language);
    });



  }

  getLanguage(langCode: string): ParseLanguage {
   return this.languages.find(pred => pred.langCode === langCode);
  }

  deleteRequest(language: ParseLanguage) {

    const query = new Parse.Query('LanguageRequest');
    query.equalTo('churchId', Parse.User.current().id);
    query.equalTo('langCode', language.langCode);

    query.find()

    .then((objects: Parse.Object[]) => objects.forEach(object => object.destroy()))

    .catch((err: Parse.Error) => console.log(err));


  }

}
