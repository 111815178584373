import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from '../../../../services/parse/translation/translation.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-archive-details',
  templateUrl: './archive-details.component.html',
  styleUrls: ['./archive-details.component.scss']
})
export class ArchiveDetailsComponent implements OnInit, OnDestroy {

  modalRef: BsModalRef;
  date: string;
  textarea = '';
  input = '';
  popoverTitle = 'Are you sure?';
  popoverMessage = 'There is no way to get them back';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public translationService: TranslationService,
    private modalService: BsModalService,
    private sharedService: SharedService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.setDateFromRoute();
  }

  ngOnDestroy(): void {
    this.translationService.archiveTranslations = [];
  }

  setDateFromRoute() {
    this.route.params.subscribe(res => {
      this.date = res.date;
      this.getTranslations();
    });
  }

  getTranslations() {
    this.translationService.getArchiveTranslations(this.date);
  }

  openModal(template: TemplateRef<any>, object: Parse.Object) {
    this.modalRef = this.modalService.show(template);
    if (object !== null) {
      this.textarea = object.attributes.text;
    }
  }

  back() {
    this.router.navigate(['app/archive']);
  }

  editTranslation(object: Parse.Object) {
    if (object.attributes.isVerse) {
      this.translate.get('APP.ARCHIVE.ERROR.BIBLE_VERSE_EDIT.TITLE').subscribe(title => {
        this.translate.get('APP.ARCHIVE.ERROR.BIBLE_VERSE_EDIT.MESSAGE').subscribe(message => {
          this.toastr.error(message, title);
        });
      });
      return;
    }

    this.translationService.editTranslation(object.attributes.timestamp, this.textarea);
    this.modalRef.hide();
    this.sharedService.gaTrackEvent('editTranslation', 'ArchiveDetails');
  }

  deleteTranslation(object: Parse.Object) {
   this.translationService.deleteTranslation(object);
   this.modalRef.hide();
   this.sharedService.gaTrackEvent('deleteTranslation', 'ArchiveDetails');
  }

  deleteSetOfTranslations() {
    this.translationService.deleteSetOfTranslations(this.date);
    this.modalRef.hide();
    this.sharedService.gaTrackEvent('deleteSetOfTranslations', 'ArchiveDetails');
  }
}
