import { Component, OnInit, TemplateRef } from '@angular/core';
import { Preparation, PreparationsGrouped } from 'src/app/interfaces/preparation.interface';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl } from '@angular/forms';
import { SharedService } from 'src/app/services/shared/shared.service';
import { PreparationService } from 'src/app/services/parse/preparation/preparation.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { VoiceTranslationService } from 'src/app/services/voice/voice-translation.service';

import * as Parse from 'parse';

@Component({
  selector: 'app-preparation',
  templateUrl: './preparation.component.html',
  styleUrls: ['./preparation.component.scss']
})
export class PreparationComponent implements OnInit {

  objectKeys = Object.keys;

  selectedPreparation: Preparation;
  selectedIndex: number;

  dateActive = true;
  prepActive = false;

  enterKey = false;
  shiftKey = false;
  textarea = '';

  dateForm: FormGroup;
  dateString: string;
  modalRef: BsModalRef;

  popoverMessage = 'Do you really want to delete all?';

  voice = false;
  recording = false;

  constructor(
    private toastr: ToastrService,
    public preparation: PreparationService,
    private modalService: BsModalService,
    private voiceService: VoiceTranslationService,
    private sharedService: SharedService
    ) { }

  async ngOnInit() {
    this.dateForm = new FormGroup({
      datepicker: new FormControl(SharedService.createNewSundayDate()),
    });
    this.voiceAvailable();
    await this.preparation.getPreparationsGroupedByDate();
    this.setPreparations();
  }

  voiceAvailable() {
    const voiceKey = Parse.User.current().attributes.voiceAzureKey;
    this.voice = voiceKey !== '';
  }

  openDetails(date: string) {
    this.preparation.openDetails(date);
    this.sharedService.gaTrackEvent('openDetails', 'Preparation');
    this.switchArchive();
  }

  switchArchive() {
    this.dateActive = !this.dateActive;
    this.prepActive = !this.prepActive;
  }

  changeDate() {
    this.setPreparations();
    this.sharedService.gaTrackEvent('changeDate', 'Preparation');
  }

  setPreparations() {
    const date = this.dateForm.controls['datepicker'].value;
    this.dateString = SharedService.getDateString(date);
    this.preparation.setPreparations(this.dateString);
  }

  openEditModal(template: TemplateRef<any>, index: number) {
    this.selectedPreparation = { ...this.preparation.preparationsArchive[index] };
    this.selectedIndex = index;
    this.modalRef = this.modalService.show(template);
  }

  editPreparation() {
    this.preparation.editPreparation(this.selectedPreparation, this.selectedIndex);
    this.hideModal();
    this.sharedService.gaTrackEvent('editPreparation', 'Preparation');
  }

  async deleteAllPreparations() {
    await this.preparation.deleteAllPreparations(this.preparation.preparationsArchive);
    this.setPreparations();
    this.switchArchive();
    // 1. Remove date from preparations
    this.sharedService.gaTrackEvent('deleteAllPreparations', 'Preparation');
  }

  async deletePreparation() {
    await this.preparation.deletePreparation(this.selectedPreparation);
    if (this.preparation.preparationsArchive.length === 0) {
      this.switchArchive();
    }
    this.hideModal();
    this.sharedService.gaTrackEvent('deletePreparation', 'Preparation');
  }

  onKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
     this.enterKey = true;
    }
    if (event.key === 'Shift') {
      this.shiftKey = true;
    }

    if (this.enterKey && this.shiftKey) {
      this.send();
      event.preventDefault();
    }
  }

  onKeyup(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.enterKey = false;
    }
    if (event.key === 'Shift') {
      this.shiftKey = false;
    }
  }

  async send() {
    if (this.textareaIsNotEmpty()) {
      if (this.recording) {
        this.record();
        this.record();
      }
      await this.preparation.savePreparation(this.textarea, this.dateForm.controls['datepicker'].value);
      this.sharedService.gaTrackEvent('send', 'Preparation');
    } else {
      this.showEmptyTextareaMessage();
    }
    this.textarea = '';
    document.getElementById('textarea').focus();
  }

  record(): void {
    if (this.recording) {
      this.voiceService.stopRecording();
      this.recording = false;
    } else {
      this.voiceService.startRecording();
      this.voiceService.startListening()
      .subscribe((text: string) => this.textarea = text);
      this.recording = true;
      this.sharedService.gaTrackEvent('record', 'Preparation');
    }

  }

  textareaIsNotEmpty(): boolean {
    return this.textarea !== '';
  }

  showEmptyTextareaMessage(): void {
      this.toastr.info('You cant create an empty preparation');
  }

  hideModal() {
    this.modalRef.hide();
  }
}
