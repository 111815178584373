export const environment = {
  production: false,
  googleAnalytics: {
    trackingId: '',
    domain: 'auto'
  },
  captchaKey: '6LclTnIUAAAAALyPb4eS89OxMEk7exSOTc_L-eTi',
  parseServer: {
    serverUrl: 'https://pg-app-py62bzm56wz6gh9djdcya7bx2texb5.scalabl.cloud/1/',
    applicationKey: '1BRW7AAFJEzPeApih2iqVSKPjOjlMTMjTOjV5qQF',
    javascriptKey: 'oRNZUpZayYH08xKvmdHd5XKVJFjlGmQMqjTVQT35'
  },
  // parseServer: {
  //   serverUrl: 'http://localhost:1337/1',
  //   applicationKey: 'asdsdfge56wtesggdfgsfdgesq35',
  //   javascriptKey: 'adfgsdpgnvsdflucgnhmsldchgsduhli34523'
  // },
  integrations: {
    urls: {
      getBiblesRequest: 'https://us-central1-church-translation-development.cloudfunctions.net/getBiblesRequest',
      getBibleBooksRequest: 'https://us-central1-church-translation-development.cloudfunctions.net/getBibleBooksRequest',
      getBibleChaptersRequest: 'https://us-central1-church-translation-development.cloudfunctions.net/getBibleChaptersRequest',
      getBibleLanguagesRequest: 'https://us-central1-church-translation-development.cloudfunctions.net/getBibleLanguagesRequest',
      getBibleVersesRequest: 'https://us-central1-church-translation-development.cloudfunctions.net/getBibleVersesRequest',
      getBibleVersesTextsRequest: 'https://us-central1-church-translation-development.cloudfunctions.net/getBibleVersesTextsRequest'
    }
  }
};
