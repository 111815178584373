import { Component, OnDestroy, OnInit } from '@angular/core';
import { Country } from '../../../../interfaces/country.interface';
import { SharedService } from '../../../../services/shared/shared.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../../services/parse/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { ParseUser } from '../../../../interfaces/user.interface';

import * as Parse from 'parse';

@Component({
  selector: 'app-personal-settings',
  templateUrl: './personal-settings.component.html',
  styleUrls: ['./personal-settings.component.scss']
})
export class PersonalSettingsComponent implements OnInit, OnDestroy {

  personalForm: FormGroup;
  emailForm: FormGroup;

  confirmTitle: string;
  confirmMessage: string;

  countriesSub: Subscription;
  countries: Country[];

  constructor(
    private shared: SharedService,
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.createPersonalForm();
    this.createEmailForm();
    this.setFormData();
    this.subscribe();
    this.setConfirmTitleAndMessage();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  setConfirmTitleAndMessage(): void {
    this.translate.get('APP.SETTINGS.PERSONAL_DATA.CHANGE_CONFIRM.TITLE').subscribe((title) => {
      this.confirmTitle = title;
    });
  }

  createPersonalForm(): void {
    this.personalForm = this.fb.group({

      translationPassword: [''],

      textAzureKey: ['', Validators.compose([
        Validators.required,
        Validators.minLength(32),
        Validators.maxLength(32)
      ])],

      voiceAzureKey: ['', Validators.compose([
        Validators.minLength(32),
        Validators.maxLength(32)
      ])],

      contactName: ['', Validators.required],

      churchName: ['', Validators.required],

      street: ['', Validators.required],

      postal: ['', Validators.required],

      city: ['', Validators.required],

      country: ['', Validators.required],
    });
  }

  createEmailForm(): void {
    this.emailForm = this.fb.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
      ])],
    });
  }

  setFormData(): void {
    const user: ParseUser = Parse.User.current().attributes;
    this.emailForm.controls['email'].setValue(user.email);
    this.personalForm.controls['translationPassword'].setValue(user.translationPassword);
    this.personalForm.controls['textAzureKey'].setValue(user.textAzureKey);
    this.personalForm.controls['voiceAzureKey'].setValue(user.voiceAzureKey);
    this.personalForm.controls['contactName'].setValue(user.contactName);
    this.personalForm.controls['churchName'].setValue(user.churchName);
    this.personalForm.controls['street'].setValue(user.street);
    this.personalForm.controls['postal'].setValue(user.postal);
    this.personalForm.controls['city'].setValue(user.city);
    this.personalForm.controls['country'].setValue(user.country);
  }

  save(): void {
    if (this.personalForm.dirty) {
      this.authService.updateUserData(this.personalForm.value);
    }
  }

  changeEmail(): void {
    this.authService.changeEmail(this.emailForm.controls['email'].value);
  }

  changePassword(): void {
    this.authService.changePassword();
  }

  subscribe(): void {
    this.subscribeAllCountries();
  }

  unsubscribe(): void {
    this.countriesSub.unsubscribe();
  }

  subscribeAllCountries(): void {
    this.countriesSub = this.shared.loadAllCountries()
      .subscribe((countries) => this.countries = countries as Country[]);
  }
}
