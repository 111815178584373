import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Bible, Book, Chapter, Verse } from '../../interfaces/bible/bible.interface';
import { BookRequestBody, ChapterRequestBody, VersesRequestBody } from '../../interfaces/bible/bibleRequest';

import * as Parse from 'parse';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../shared/shared.service';
import { PreparationService } from '../parse/preparation/preparation.service';
import { SettingsService } from '../parse/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class BiblesService {

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private preparation: PreparationService,
    private settings: SettingsService
  ) {
  }

  async getBibles(): Promise<Bible[]> {
    const bibles = await (this.http.get(environment.integrations.urls.getBiblesRequest).toPromise() as unknown as Promise<Bible[]>);
    return bibles.sort((a, b) => a.language.name < b.language.name ? -1 : 1);
  }

  async getbooks(): Promise<Book[]> {
    const body: BookRequestBody = { bibleId: this.settings.selectedBible };
    return await (this.http.post(environment.integrations.urls.getBibleBooksRequest, body).toPromise() as unknown as Promise<Book[]>);
  }

  async getChapters(bookId: string): Promise<Chapter[]> {
    const body: ChapterRequestBody = { bibleId: this.settings.selectedBible, bookId };
    return await (this.http.post(environment.integrations.urls.getBibleChaptersRequest, body).toPromise() as unknown as Promise<Chapter[]>);
  }

  async getVerses(chapterId: string): Promise<Verse[]> {
    const body: VersesRequestBody = { bibleId: this.settings.selectedBible, chapterId };
    return await (this.http.post(environment.integrations.urls.getBibleVersesRequest, body).toPromise() as unknown as Promise<Verse[]>);
  }

  sendVerses(chapterId: string, fromVerseId: string, toVerseId: string) {

    Parse.Cloud.run('translateBible', { chapterId, fromVerseId, toVerseId, date: SharedService.getDateString(new Date()) })
      .catch((err: Parse.Error) => this.toastr.error(err.message, 'Send Bible Error'));
  }

  prepareVerses(chapterId: string, fromVerseId: string, toVerseId: string, date: string) {
    Parse.Cloud.run('prepareVerses', { chapterId, fromVerseId, toVerseId, date })
      .then((res: Parse.Object) => this.preparation.addPreparation(res, res.attributes.date))
      .catch((err: Parse.Error) => this.toastr.error(err.message, 'Send Bible Error'));
  }
}
