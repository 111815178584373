import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { LoginComponent } from '../authentication/login/login.component';
import { SignupComponent } from '../authentication/signup/signup.component';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit {

  loginModalRef: BsModalRef;
  signupModalRef: BsModalRef;
  modalonfig: ModalOptions = {
    ignoreBackdropClick: true,
    class: 'modal-lg'
  };

  constructor(private modalService: BsModalService) { }

  ngOnInit() { }

  openLoginModal(e: Event) {
    e.preventDefault();

    this.loginModalRef = this.modalService.show(LoginComponent, this.modalonfig);
    this.loginModalRef.content.closeBtnName = 'Close';
  }

  openSignupModal() {
    this.signupModalRef = this.modalService.show(SignupComponent, this.modalonfig);
    this.signupModalRef.content.closeBtnName = 'Close';
  }

  moveToNews(e: Event) {
    e.preventDefault();
    window.scrollTo(0, document.body.scrollHeight);
  }
}
