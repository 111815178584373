import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguagesService } from '../../../../services/parse/languages/languages.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedLanguage, ParseLanguage, VoiceLanguage } from '../../../../interfaces/language.interface';
import { SharedService } from '../../../../services/shared/shared.service';
import { BiblesService } from '../../../../services/bibles/bibles.service';
import { Bible } from '../../../../interfaces/bible/bible.interface';
import { SettingsService } from '../../../../services/parse/settings/settings.service';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})

export class GeneralSettingsComponent implements OnInit {

  activatedLanguages;
  languages;
  voiceLanguages: VoiceLanguage[];

  activatedLanguagesSettings = {};
  bibles: Bible[];

  constructor(
    private translate: TranslateService,
    private language: LanguagesService,
    private toastr: ToastrService,
    private shared: SharedService,
    private biblesService: BiblesService,
    public settingsService: SettingsService
  ) {
  }

  async ngOnInit() {
    this.createActivatedLanguagesSettings();

    const languagesPromise = this.language.loadLanguages();
    const activatedLanguagesPromise = this.language.loadActivatedLanguages();
    const voiceLanguagesPromise = this.language.loadVoiceLanguages();
    const biblePromise = this.biblesService.getBibles();

    this.languages = await languagesPromise;
    this.activatedLanguages = await activatedLanguagesPromise;
    this.voiceLanguages = await voiceLanguagesPromise;
    this.bibles = await biblePromise;
  }

  changeBible(bibleId: string) {
    this.settingsService.selectedBible = bibleId;
    this.settingsService.saveSettings();
    this.shared.gaTrackEvent('changeBible', 'GeneralSettings', bibleId);
  }

  changeTranslationLanguage(language: string) {
    this.settingsService.selectedTranslationLanguage = language;
    const lang = { langCode: language };
    this.settingsService.saveSettings();

    if (!SharedService.isActivatedLanguageAlreadyInArray(language, this.activatedLanguages)) {
      this.language.selectActivatedLanguage(lang);
      this.shared.gaTrackEvent('changeTranslationLanguage', 'GeneralSettings', language);
    }
  }

  changeVoiceLanguage(language: string) {
   this.settingsService.selectedVoiceLanguage = language;
    this.settingsService.saveSettings();
    this.shared.gaTrackEvent('changeVoiceLanguage', 'GeneralSettings', language);
  }

  changeArchiveLanguage(language: string) {
    this.settingsService.selectedArchiveLanguage = language;
    const lang = { langCode: language };

    this.settingsService.saveSettings();

    if (!SharedService.isActivatedLanguageAlreadyInArray(language, this.activatedLanguages)) {
      this.language.selectActivatedLanguage(lang);
      this.shared.gaTrackEvent('changeArchiveLanguage', 'GeneralSettings', language);

    }
  }

  createActivatedLanguagesSettings() {
    this.activatedLanguages = this.language.activatedLanguages;
    this.activatedLanguagesSettings = {
      idField: 'langCode',
      enableCheckAll: false,
      textField: 'name',
      allowSearchFilter: true,
      limitSelection: 15
    };
  }

  selectActivatedLanguage(language) {
    this.language.selectActivatedLanguage(language);
    this.shared.gaTrackEvent('selectActivatedLanguage', 'GeneralSettings', language.langCode);
  }

  deSelectActivatedLanguage(language) {
    if (this.activatedLanguageIsNotDeletable(language)) {
      this.activatedLanguageIsNotDeletableError(language);
      this.restoreActivatedLanguages(language);
    } else {
      this.language.deSelectActivatedLanguage(language);
      this.shared.gaTrackEvent('deSelectActivatedLanguage', 'GeneralSettings', language.langCode);
    }
  }

  activatedLanguageIsNotDeletable(language: ActivatedLanguage) {
    return this.settingsService.selectedTranslationLanguage === language.langCode
    || language.langCode === 'en'
    || this.settingsService.selectedArchiveLanguage === language.langCode;
  }

  activatedLanguageIsNotDeletableError(language: ActivatedLanguage) {
    if (language.langCode === 'en') {
      this.translate.get('APP.SETTINGS.GENERAL.ERROR.ACTIVATED_LANGUAGES_ENGLISH.TITLE').subscribe(title => {
        this.translate.get('APP.SETTINGS.GENERAL.ERROR.ACTIVATED_LANGUAGES_ENGLISH.MESSAGE').subscribe(message => {
          this.toastr.error(message, title);
        });
      });
    } else {
      this.translate.get('APP.SETTINGS.GENERAL.ERROR.ACTIVATED_LANGUAGES.TITLE').subscribe(title => {
        this.translate.get('APP.SETTINGS.GENERAL.ERROR.ACTIVATED_LANGUAGES.MESSAGE').subscribe(message => {
          this.toastr.error(message, title);
        });
      });
    }
  }

  restoreActivatedLanguages(language: ActivatedLanguage) {
    const activatedOld: ActivatedLanguage[] | ParseLanguage[] = this.activatedLanguages;
    this.activatedLanguages = [language];
    for (const lang of activatedOld) {
      this.activatedLanguages.push((lang as ActivatedLanguage));
      this.activatedLanguages.sort(SharedService.sortLanguagesArray);
    }
  }

}
