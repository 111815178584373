import { Component, Input, OnInit } from '@angular/core';
import { Book, Chapter, Verse } from '../../interfaces/bible/bible.interface';
import { BiblesService } from '../../services/bibles/bibles.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bible-select',
  templateUrl: './bible-select.component.html',
  styleUrls: ['./bible-select.component.scss']
})
export class BibleSelectComponent implements OnInit {

  @Input() isPreparation: boolean;
  @Input() date: string;

  books: Book[];
  chapters: Chapter[];
  fromVerses: Verse[];
  toVerses: Verse[];

  selectedBook: string;
  selectedChapter: string;
  selectedFromVerse: string;
  selectedToVerse: string;

  constructor(
    private biblesService: BiblesService,
    private translate: TranslateService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.setBooks().catch(err => console.log(err));
    this.resetSelects();
  }

  async setBooks() {
    this.books = await this.biblesService.getbooks();
  }

  async setChapter(bookId: string) {
    this.chapters = await this.biblesService.getChapters(bookId);
  }

  async setVerses(chapterId: string) {
    this.fromVerses = await this.biblesService.getVerses(chapterId);
  }

  selectBook() {
    this.setChapter(this.selectedBook).catch(err => console.log(err));
  }

  selectChapter() {
    this.setVerses(this.selectedChapter).catch(err => console.log(err));
  }

  selectFromVerse() {
    this.filterToVerses();
  }

  filterToVerses() {
    const fromVerse = this.fromVerses.find(verse => verse.id === this.selectedFromVerse);
    this.toVerses = this.fromVerses.filter(verse => verse.number > fromVerse.number);
  }

  resetSelects() {
    this.selectedBook = '';
    this.selectedChapter = '';
    this.selectedFromVerse = '';
    this.selectedToVerse = '';

    this.chapters = [];
    this.fromVerses = [];
    this.toVerses = [];
  }

  noVerseError() {
    this.translate.get('APP.BIBLE_SELECT.ERROR.NO_VERSE.TITLE').subscribe(title => {
      this.translate.get('APP.BIBLE_SELECT.ERROR.NO_VERSE.MESSAGE').subscribe(message => {
        this.toastr.error(message, title);
      });
    });
  }

  sendBible(): void {
    if (!this.selectedFromVerse) {
      return this.noVerseError();
    }
    this.isPreparation ? this.sendPreparationBible() : this.sendTranslationBible();
    this.resetSelects();
  }

  sendTranslationBible(): void {
    this.biblesService.sendVerses(this.selectedChapter, this.selectedFromVerse, this.selectedToVerse);
  }

  sendPreparationBible(): void {
    this.biblesService.prepareVerses(this.selectedChapter, this.selectedFromVerse, this.selectedToVerse, this.date);
  }

}
