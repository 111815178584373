import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as Parse from 'parse';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../parse/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class VoiceTranslationService {

  subscriptionKey: string;
  serviceRegion: string;
  speechConfig: SpeechSDK.SpeechConfig;
  recognizer: SpeechSDK.SpeechRecognizer;
  audioConfig: SpeechSDK.AudioConfig;

  constructor(
    private toastr: ToastrService,
    private settings: SettingsService
  ) {}

  configSpeechSDK() {
    this.subscriptionKey = Parse.User.current().attributes.voiceAzureKey;
    this.serviceRegion = 'northeurope';
    this.speechConfig = SpeechSDK.SpeechConfig.fromSubscription(this.subscriptionKey, this.serviceRegion);
    this.speechConfig.speechRecognitionLanguage = this.settings.selectedVoiceLanguage;
    this.audioConfig  = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
   }

  startRecording() {
    this.configSpeechSDK();
    this.recognizer = new SpeechSDK.SpeechRecognizer(this.speechConfig, this.audioConfig);
    this.recognizer.startContinuousRecognitionAsync(
      () => console.log('START LISTENING'),
      e => {
        console.log('HURRA');
        this.toastr.error(e, 'startRecording Error');
      }
      );
  }

  startListening(): Observable<string> {

    return new Observable<string>((observer) => {
      let recognizedText = '';
      let recognizingText = '';

      this.recognizer.recognizing = function(sender, event) {
        recognizingText = recognizedText + ' ' + event.result.text;
        observer.next(recognizingText);
      };

      this.recognizer.recognized = function(sender, event) {
        recognizedText = recognizedText + ' ' + event.result.text;
        observer.next(recognizedText);
      };
    });
  }

  stopRecording() {
    this.recognizer.stopContinuousRecognitionAsync(
      () => console.log('STOP LISTENING'),
      e => console.log(e)
    );
  }
}
