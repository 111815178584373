import { Routes } from '@angular/router';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { TranslationAppComponent } from './pages/translation-app/translation-app.component';
import { TranslationComponent } from './pages/translation-app/translation/translation.component';
import { PreparationComponent } from './pages/translation-app/preparation/preparation.component';
import { ArchiveComponent } from './pages/translation-app/archive/archive.component';
import { SettingsComponent } from './pages/translation-app/settings/settings.component';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { ArchiveDetailsComponent } from './pages/translation-app/archive/archive-details/archive-details.component';

export const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'app', component: TranslationAppComponent, canActivate: [AuthGuardService], children: [
      { path: '', redirectTo: 'translation', pathMatch: 'full', canActivate: [AuthGuardService] },
      { path: 'translation', component: TranslationComponent, canActivate: [AuthGuardService] },
      { path: 'preparation', component: PreparationComponent, canActivate: [AuthGuardService] },
      { path: 'archive', component: ArchiveComponent, canActivate: [AuthGuardService] },
      { path: 'archive-details/:date', component: ArchiveDetailsComponent, canActivate: [AuthGuardService], },
      { path: 'settings', component: SettingsComponent, canActivate: [AuthGuardService] },
    ]},
  { path: '**', component: LandingPageComponent },
];
