import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { LoginComponent } from './pages/landing-page/authentication/login/login.component';
import { SignupComponent } from './pages/landing-page/authentication/signup/signup.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { AuthenticationService } from './services/parse/authentication/authentication.service';
import { SharedService } from './services/shared/shared.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { TranslationAppComponent } from './pages/translation-app/translation-app.component';
import { TranslationComponent } from './pages/translation-app/translation/translation.component';
import { PreparationComponent } from './pages/translation-app/preparation/preparation.component';
import { SettingsComponent } from './pages/translation-app/settings/settings.component';
import { ArchiveComponent } from './pages/translation-app/archive/archive.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SidebarModule } from 'ng-sidebar';
import { GeneralSettingsComponent } from './pages/translation-app/settings/general-settings/general-settings.component';
import { PersonalSettingsComponent } from './pages/translation-app/settings/personal-settings/personal-settings.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { WebStorageModule } from 'ngx-store';
import { TranslationService } from './services/parse/translation/translation.service';
import { ArchiveDetailsComponent } from './pages/translation-app/archive/archive-details/archive-details.component';
import { QuestionPageComponent } from './pages/landing-page/question-page/question-page.component';
import { WebappPageComponent } from './pages/landing-page/webapp-page/webapp-page.component';
import { SmartphoneappPageComponent } from './pages/landing-page/smartphoneapp-page/smartphoneapp-page.component';
import { VideoPageComponent } from './pages/landing-page/video-page/video-page.component';
import { StepsPageComponent } from './pages/landing-page/steps-page/steps-page.component';
import { FooterComponent } from './pages/landing-page/footer/footer.component';
import { StartPageComponent } from './pages/landing-page/start-page/start-page.component';
import { NgxAnalyticsModule } from 'ngx-analytics';
import { NgxAnalyticsGoogleAnalytics } from 'ngx-analytics/ga';
import { environment } from 'src/environments/environment';
import { BibleSelectComponent } from './components/bible-select/bible-select.component';
import { UiSwitchModule } from 'ngx-toggle-switch';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  entryComponents: [ LoginComponent, SignupComponent ],
  declarations: [
    AppComponent,
    LandingPageComponent,
    LoginComponent,
    SignupComponent,
    TranslationAppComponent,
    TranslationComponent,
    PreparationComponent,
    SettingsComponent,
    ArchiveComponent,
    GeneralSettingsComponent,
    PersonalSettingsComponent,
    ArchiveDetailsComponent,
    QuestionPageComponent,
    WebappPageComponent,
    SmartphoneappPageComponent,
    VideoPageComponent,
    StepsPageComponent,
    FooterComponent,
    StartPageComponent,
    BibleSelectComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgSelectModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SidebarModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    ConfirmationPopoverModule.forRoot(),
    WebStorageModule,
    NgxAnalyticsModule.forRoot([NgxAnalyticsGoogleAnalytics]),
    UiSwitchModule
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.captchaKey } as RecaptchaSettings,
    },
    AuthenticationService,
    SharedService,
    AuthGuardService,
    TranslationService
  ],
  bootstrap: [ AppComponent ]
})

export class AppModule {

}
