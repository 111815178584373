import { Injectable } from '@angular/core';
import { Settings } from '../../../interfaces/settings';

import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  selectedBible;
  selectedArchiveLanguage;
  selectedTranslationLanguage;
  selectedVoiceLanguage;

  constructor() {
    this.setSettings();
  }

  createSettings(): Settings {
    return {
      bible: this.selectedBible,
      archiveLanguage: this.selectedArchiveLanguage,
      translationLanguage: this.selectedTranslationLanguage,
      voiceLanguage: this.selectedVoiceLanguage
    };
  }

  saveSettings(): void {
    const settings = this.createSettings();

    Parse.User.current().save({
      settings: settings
    });
  }

  setSettings(): void {
    if (!Parse.User.current()) { return; }

    const settings: Settings = Parse.User.current().attributes.settings;
    this.selectedBible = settings.bible;
    this.selectedArchiveLanguage = settings.archiveLanguage;
    this.selectedTranslationLanguage = settings.translationLanguage;
    this.selectedVoiceLanguage = settings.voiceLanguage;

  }
}
