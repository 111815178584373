import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PasswordValidator } from '../validators/password-validator';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Country } from '../../../../interfaces/country.interface';
import { AuthenticationService } from 'src/app/services/parse/authentication/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { SharedService } from '../../../../services/shared/shared.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {

  signupForm: FormGroup;

  countriesSub: Subscription;
  countries: Country[];

  activatedLanguageCode = 'de';

  emailInUse: boolean;
  weakPassword: boolean;

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private shared: SharedService) { }

  ngOnInit() {
    this.loadAllCountries();
    this.createSignupForm();
  }

  ngOnDestroy(): void {
    this.countriesSub.unsubscribe();
  }

  createSignupForm(): void {
    this.signupForm = this.fb.group({

      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
      ])],

      passwords: this.fb.group({
        password: ['', Validators.compose([
          Validators.required,
          Validators.minLength(8)
        ])],
        passwordConfirm: ['', Validators.compose([
          Validators.required
        ])]
      }, {
        validator: PasswordValidator.validate.bind(this)
      }),

      translationPassword: [''],

      textAzureKey: ['', Validators.compose([
        Validators.required,
        Validators.minLength(32),
        Validators.maxLength(32)
      ])],

      voiceAzureKey: ['', Validators.compose([
        Validators.minLength(32),
        Validators.maxLength(32)
      ])],

      contactName: ['', Validators.required],

      churchName: ['', Validators.required],

      street: ['', Validators.required],

      postal: ['', Validators.required],

      city: ['', Validators.required],

      country: ['Germany', Validators.required],

      recaptcha: ['', Validators.required]

    });
  }

  loadAllCountries(): void {
    this.countriesSub = this.shared.loadAllCountries()
      .subscribe((countries) => this.countries = countries as Country[]);
  }

  signup() {
    this.authService.userSignup(this.signupForm.value, this.activatedLanguageCode)

      .then(() => {
        this.signupSuccess();
      })

      .catch((err: Parse.Error) => {
        this.signupError(err);
      });
  }

  signupSuccess() {
    this.bsModalRef.hide();

    this.translate.get('AUTHENTICATION.SUCCESS.TITLE')
      .subscribe((title: string) => {
        this.translate.get('AUTHENTICATION.SUCCESS.MESSAGE')
          .subscribe((message: string) => {
            this.toastr.success(message, title);
          });
      });
  }

  signupError(error: Parse.Error) {
    this.toastr.error(error.message, 'Signup Error');
  }

  onCountryChange(event: Country) {
    if (event) {
      this.activatedLanguageCode = event.languages[0].iso639_1;
    }
  }

}
