import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { AuthenticationService } from '../../../../services/parse/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  invalidEmail = false;
  userNotFound = false;
  wrongPassword = false;

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private authService: AuthenticationService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private router: Router) {

  }

  ngOnInit() {
    this.createLoginForm();
  }

  createLoginForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });
  }

  login() {

    this.authService.userLogin(this.loginForm.value)

      .then(() => this.router.navigate(['app']))
      .then(() => this.bsModalRef.hide())
      .catch((err) => this.loginError(err));

  }

  loginError(error: Parse.Error) {
    this.toastr.error(error.message, 'Login Error');
  }

}
