import { Injectable } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { ToastrService } from 'ngx-toastr';

import * as Parse from 'parse';
import { LanguagesService } from '../languages/languages.service';
import { Router } from '@angular/router';
import { SettingsService } from '../settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  archiveDates: string[] = [];
  archiveTranslations: Parse.Object[] = [];

  translations: Parse.Object[] = [];
  translationsSub: Parse.LiveQuerySubscription;

  constructor(
    private toastr: ToastrService,
    private language: LanguagesService,
    private router: Router,
    private settings: SettingsService
  ) {
  }

  translate(text: string): void {

    Parse.Cloud.run('translate', { text: text, date: SharedService.getDateString(new Date()) })

      .catch((err: Parse.Error) => this.toastr.error(err.message, 'Translate Error'));
  }

  translateArchive(): Promise<boolean> {

    return new Promise((resolve, reject) => {

      if (this.language.languagesToTranslate.length > 0) {

        Parse.Cloud.run('translateArchive', {
          langCodes: this.language.languagesToTranslate,
          date: SharedService.getDateString(new Date())
        });

        this.language.languagesToTranslate = [];
      }

      resolve(true);
    });

  }

  getTranslations(): void {

    const query = new Parse.Query('Translations');
    query.equalTo('churchId', Parse.User.current().id);
    query.equalTo('langCode', this.settings.selectedTranslationLanguage);
    query.equalTo('date', SharedService.getDateString(new Date()));
    query.addDescending('timestamp');
    query.limit(1000000);

    query.find()
      .then((res: Parse.Object[]) => {
        this.translations = res;
        this.subscribeTranslations(query);
      })
      .catch((err: Parse.Error) => {
        this.toastr.error(err.message, 'GetTranslations Error');
      });
  }

  getArchiveDates(): void {

    const query = new Parse.Query('Translations');

    query.equalTo('churchId', Parse.User.current().id);
    query.equalTo('langCode', this.settings.selectedTranslationLanguage);
    query.addDescending('timestamp');
    query.select('date');
    query.limit(1000000);

    query.find()
      .then((objects: Parse.Object[]) => this.setArchiveDates(objects))
      .catch((err: Parse.Error) => this.toastr.error(err.message, 'getArchiveDates Error'));
  }

  setArchiveDates(objects: Parse.Object[]): void {

    if (objects.length > 0) {
      for (const object of objects) {
        const date = object.attributes.date;
        if (!this.isDateAlreadyInArray(date, this.archiveDates)) {
          this.archiveDates.push(date);
        }
      }
    }
  }

  getArchiveTranslations(date: string): void {

    const query = new Parse.Query('Translations');

    query.equalTo('churchId', Parse.User.current().id);
    query.equalTo('date', date);
    query.equalTo('langCode', this.settings.selectedArchiveLanguage);
    query.addAscending('timestamp');
    query.limit(1000000);

    query.find()
      .then((objects: Parse.Object[]) => this.archiveTranslations = objects)
      .catch((err: Parse.Error) => this.toastr.error(err.message, 'getArchiveTranslations Error'));

  }

  editTranslation(timestamp: Date, text: string) {

    Parse.Cloud.run('editTranslation', { timestamp: timestamp, text: text })

      .then((res: Parse.Object[]) => this.toastr.success('Translation is updated', 'Success'))
      .catch((err: Parse.Error) => this.toastr.error(err.message, 'editTranslation Error'));
  }

  deleteTranslation(object: Parse.Object) {
    Parse.Cloud.run('deleteTranslation', { timestamp: object.attributes.timestamp })

      .then((res: Parse.Object[]) => {
        this.toastr.success('Translation is deleted', 'Success');
        this.removeObjectFromArchive(object);
      })
      .catch((err: Parse.Error) => this.toastr.error(err.message, 'deleteTranslation Error'));
  }

  deleteSetOfTranslations(date: string) {

    Parse.Cloud.run('deleteAllTranslations', { date: date })

      .then(() => {
        this.toastr.success('Set of Translations is deleted', 'Success');
        this.router.navigate(['app/archive']);
      })

      .catch((err: Parse.Error) => this.toastr.error(err.message, 'deleteSetOfTranslations Error'));
  }

  isDateAlreadyInArray(date: string, array: string[]) {
    return array.indexOf(date) !== -1;
  }

  subscribeTranslations(query: Parse.Query): void {

    if (this.translationsAreSubscribed()) {
      this.unsubscribeTranslations();
      this.translationsSub.on('close', () => {
        this.createSubscription(query);
      });
    } else {
      this.createSubscription(query);
    }
  }

  createSubscription(query: Parse.Query): void {
    this.translationsSub = query.subscribe();

    this.translationsSub.on('create', (translation) => {
      this.translations.unshift(translation);
    });
  }


  unsubscribeTranslations(): void {
    this.translationsSub.unsubscribe();
  }

  translationsAreSubscribed(): boolean {
    return !!this.translationsSub;
  }

  removeObjectFromArchive(object: Parse.Object): void {
    const index = this.archiveTranslations.indexOf(object);

    if (index !== -1) {
      this.archiveTranslations.splice(index, 1);
    }
  }
}
