import { Injectable } from '@angular/core';
import { FormUser, LoginUser, ParseUser, SignupUser } from 'src/app/interfaces/user.interface';
import { SharedService } from '../../shared/shared.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import * as Parse from 'parse';
import { TranslationService } from '../translation/translation.service';
import { SettingsService } from '../settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService,
    private translation: TranslationService,
    private settings: SettingsService
  ) {
  }

  userSignup(formUser: FormUser, langCode: string): Promise<Parse.User> {

    return new Promise((resolve, reject) => {

      const signupUser: SignupUser = SharedService.convertFormUserToSignupUser(formUser, langCode);

      const user: Parse.User = new Parse.User();

      user.signUp(signupUser)

        .then((res: any) => resolve(res))

        .catch((err: Parse.Error) => {
          reject(err);
          this.userLogout();
        });

    });
  }

  userLogin(loginUser: LoginUser): Promise<Parse.User> {

    return new Promise((resolve, reject) => {

      Parse.User.logIn(loginUser.email, loginUser.password)
        .then((res: Parse.User) => {
          this.settings.setSettings();
          resolve(res);
        })
        .catch((err: Parse.Error) => {
          this.userLogout();
          reject(err);
        });
    });
  }

  userLogout(): void {

    this.translation.translateArchive()
      .then(() => Parse.User.logOut())

      .then((res: Parse.User) => this.router.navigate(['']))

      .catch((err: Parse.Error) => this.toastr.error(err.message, 'Logout Error'));
  }

  isUserLoggedIn(): boolean {
    return Parse.User.current() !== null;
  }

  changePassword() {

    const email = (Parse.User.current().attributes as ParseUser).email;

    Parse.User.requestPasswordReset(email)

      .then(res => {
        this.changePasswordMessage();
      })

      .catch((err: Parse.Error) => {
        this.toastr.error(err.message, 'Change Password Error');
      });

  }

  changeEmail(email: string) {

    Parse.User.current().save({
      email: email,
      username: email
    })
      .then(res => {
        this.changeEmailMessage();
      })

      .catch((err: Parse.Error) => {
        this.toastr.error(err.message, 'Change Email Error');
      });
  }

  updateUserData(user: Partial<FormUser> ) {

    Parse.User.current().save(user)

      .then(() => {
        this.updateUserDataSuccessMessage();
      })

      .catch((err: Parse.Error) => {
        this.toastr.error(err.message, 'Update User Error');
      });

  }

  updateUserDataSuccessMessage() {
    this.translate.get('APP.SETTINGS.PERSONAL_DATA.SUCCESS_MESSAGE.TITLE')
      .subscribe((title: string) => {
        this.translate.get('APP.SETTINGS.PERSONAL_DATA.SUCCESS_MESSAGE.MESSAGE')
          .subscribe((message: string) => {
            this.toastr.success(message, title);
          });
      });
  }

  changePasswordMessage() {
    this.translate.get('APP.SETTINGS.PERSONAL_DATA.PASSWORD_RESET_SUCCESS.TITLE')
      .subscribe((title: string) => {
        this.translate.get('APP.SETTINGS.PERSONAL_DATA.PASSWORD_RESET_SUCCESS.MESSAGE')
          .subscribe((message: string) => {
            this.toastr.success(message, title);
          });
      });
  }

  changeEmailMessage() {

    this.translate.get('APP.SETTINGS.PERSONAL_DATA.EMAIL_CHANGE.MESSAGE')

      .subscribe((message: string) => {
        this.toastr.success(message);
      });
  }
}
